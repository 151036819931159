/**
 * Configuration required for Amplify.
 * Ref: https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/
 */

export const envConfig = {
  Auth: {
    // (required) only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: 'us-west-2:16a32184-ca60-4064-abc8-6bec1c5d9a76',

    // (required)- Amazon Cognito Region
    region: 'us-west-2',

    // (optional) - Amazon Cognito User Pool ID
    userPoolId: 'us-west-2_owjK48DPR',

    // (optional) - Amazon Cognito Web Client ID (26-char alphanumeric string, App client secret needs to be disabled)
    userPoolWebClientId: '7ciimreinq7v5r2hnuprc3aae7',

    // (optional) - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    // (optional) - Hosted UI configuration
    oauth: {
      domain: 'mec-access-control-gamma' + '.auth.us-west-2.amazoncognito.com',
      redirectSignIn: 'https://' + window.location.host + '/',
      redirectSignOut: 'https://' + window.location.host + '/',
      scope: ['openid', 'email', 'profile'],
      responseType: 'code',
    },
  },
  API: {
    endpoints: [
      {
        name: 'GalaxySharedServiceAPI',
        endpoint: 'https://vdy6y2ic9j.execute-api.us-west-2.amazonaws.com/gamma',
      },
      {
        name: 'DataService',
        endpoint: 'https://api.gamma-data-service.mec.asft.amazon.dev',
      },
      {
        name: 'SettingService',
        endpoint: 'https://67g7ypzrs1.execute-api.us-west-2.amazonaws.com/gamma',
      },
      {
        name: 'JournalEntryService',
        endpoint: 'https://api.gamma-journal-entry-service.mec.asft.amazon.dev',
      },
      {
        name: 'RuleService',
        endpoint: 'https://api.gamma-rule-service.mec.asft.amazon.dev',
      },
    ],
  },
  Storage: {},
};

export const storageBucketRegions: Record<string, string> = {
  '{{JETransferStorage}}': 'us-west-2',
  'mec-jeservice-data-gamma': 'us-west-2',
  'mec-ruleservice-data-gamma': 'us-west-2',
  'mec-ruleservice-transfer-gamma': 'us-west-2',
  'mec-dataservice-user-exports-gamma': 'us-east-1',
};
